import styled from "styled-components";
import {
  Rem,
  BP,
  Colors,
} from "../../../commons/Theme";
import { Button } from "@3beehivetech/components";
import {
  StyledWrapperButton,
} from "../BeeWho/style";

export const StyledYellowBar = styled.div`
  background-color: #FFF9EC;
  width: 100%;
  height: 410px;
  margin-bottom: 3rem;
  @media (${BP.tablet}) {
    margin-bottom: 0;
    margin: 0 auto;
    height: 110px;
  }
`;
export const WhoWeAreWrapper = styled.div`
  position: relative;
`;

export const WhoWeAreContentWrapper = styled.div`
  padding: ${Rem(90)} 0;
  background: url(${({ backgroundImage }) => backgroundImage});
  background-repeat: round;
  background-size: contain;
  position: relative;
  width: 100%;
  @media (${BP.tablet}) {
    background-size: cover;
    background-repeat: repeat;
    margin: 0 auto;
    padding: ${Rem(100)} 0;
    min-height: 75vh;
  }
  @media (${BP.big}) {
    min-height: 55vh;
  }
`;

export const WrapperCard = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  max-width: 93rem;
  @media (${BP.tablet}) {
    max-width:1248px;
    margin: 0 auto;
  }
`;

export const StyledBackground = styled.div`
  width: 100%;
`;

export const WrapperItemsCard = styled.div`
  position: absolute;
  height: unset;
  right: 0;
  margin: 0 1rem;
  top: 180%;
  transform: translate(0, -50%);
  display: flex;
  gap: 2rem;
  flex-direction: column;
  justify-content: center;
  padding: 2rem 1.25rem;
  max-width: 475px;
  background-color: ${Colors.yellow};
  border-radius: 20px;
  @media (${BP.tablet}) {
    margin: 0;
    height: 112%;
    top: 50%;
    right: 15%;
    padding: 4rem 3rem;
  }
`;

export const WrapperItem = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 1.5rem;
`;

export const StyledPreTitle = styled.div`
  color: ${Colors.black};
  font-size: ${Rem(20)};
`;

export const StyledParagraph = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  color: ${Colors.white};
`;

export const StyledTitle = styled.h2`
  color: ${Colors.white};
  font-size: ${Rem(45)};
  font-weight: 600;
  text-align: left;
  @media (${BP.tablet}) {
    font-size: ${Rem(60)};
  }
`;

export const StyledText = styled.p`
  color: ${Colors.black};
  font-size: ${Rem(14)};
  font-weight: 300;
  text-align: left;
  @media (${BP.tablet}) {
    font-size: ${Rem(18)};
  }
`;

export const WrapperWhoButton = styled(StyledWrapperButton)`
  padding-top: 0;
  @media (${BP.tablet}) {
    padding-top: 2rem;
  }
`;

export const StyledWhoCta = styled(Button)`
  display: flex;
  background-color: ${Colors.white} !important;
  padding: ${Rem(0)} !important;
  width: 100%;
  a {
    width: 100%;
    text-decoration: none;
    color: ${Colors.yellow};
    padding: 1.5rem 0;
  }
  @media (${BP.tablet}) {
    width: unset;
    a {
      /* padding: ${Rem(16)} ${Rem(137)}; */
      padding: 1.5rem 5.5625rem;
    }
  }
`;
