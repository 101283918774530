import styled from "styled-components";
import {
  Rem,
  BP,
  Colors,
} from "../../../commons/Theme";
import { Button } from "@3beehivetech/components";

export const StyledBeeSectionTitle = styled.h1`
  text-align: left;
  font-size: ${Rem(32)};
  padding: 0 2rem;
  padding-top: 0;
  @media (${BP.tablet}) {
    font-size: ${Rem(40)};
  }
`;
export const BeeWhoWrapper = styled.div`
  padding: ${Rem(100)} 0;
  position: relative;
  display: flex;
  flex-direction: column-reverse;
  width: 100%;
  max-width: 93rem;
  @media (${BP.tablet}) {
    flex-direction: row;
    max-width:1248px;
    margin: 0 auto;
    gap: 10rem;
    padding: ${Rem(100)} 0;
  }
`;

export const WrapperContent = styled.div`
  position: relative;
`;

export const WrapperImage = styled.div`
  position: absolute;
  top: 20%;
  right: 2.25rem;
  width: 125px;
  @media (${BP.tablet}) {
    position: initial;
    min-width: 45%;
  }
`;

export const StyledTwoColWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

export const StyledWrapperButton = styled.div`
  z-index: 3;
  width: 100%;
  padding: 0 2rem;
  @media (${BP.tablet}) {
    width: unset;
  }
`;

export const StyledBeeCta = styled(Button)`
  display: flex;
  padding: ${Rem(0)} !important;
  width: 100%;
  &:hover {
    a {
      color: ${Colors.yellow};
    }  
  }
  a {
    width: 100%;
    text-decoration: none;
    color: ${Colors.white};
    padding: ${Rem(16)} ${Rem(0)};
  }
  @media (${BP.tablet}) {
    width: unset;
    a {
      padding: ${Rem(16)} ${Rem(137)};
    }
  }
`;
